import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";

import "./SosMainExcerpt.scss";

const SosMainExcerpt = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post || {};
  const { title, description } = frontmatter || {};
  return (
    <Link className="SosMain__Excerpt" to="/sos-main">
      <div className="SosMain__ExcerptHeader">
        <h3>{title}</h3>
      </div>
      <div className="SosMain__ExcerptContent">
        <p>{description}</p>
      </div>
    </Link>
  );
};

SosMainExcerpt.propTypes = {
  data: PropTypes.object.isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query SosMainExcerpt {
        markdownRemark(frontmatter: { templateKey: { eq: "sos-main" } }) {
          frontmatter {
            title
            description
          }
        }
      }
    `}
    render={(data, count) => <SosMainExcerpt data={data} count={count} />}
  />
);

import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";

import "./OperationsProgrammeesExcerpt.scss";

const OperationsProgrammeesExcerpt = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post || {};
  const { title, description } = frontmatter || {};
  return (
    <Link
      className="OperationsProgrammees__Excerpt"
      to="/operations-programmees"
    >
      <div className="OperationsProgrammees__ExcerptHeader">
        <h3>{title}</h3>
      </div>
      <div className="OperationsProgrammees__ExcerptContent">
        <p>{description}</p>
      </div>
    </Link>
  );
};

OperationsProgrammeesExcerpt.propTypes = {
  data: PropTypes.object.isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query OperationsProgrammeesExcerpt {
        markdownRemark(
          frontmatter: { templateKey: { eq: "operations-programmees" } }
        ) {
          frontmatter {
            title
            description
          }
        }
      }
    `}
    render={(data, count) => (
      <OperationsProgrammeesExcerpt data={data} count={count} />
    )}
  />
);

import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import SosMainExcerpt from "../components/SosMainExcerpt";
import OperationsProgrammeesExcerpt from "../components/OperationsProgrammeesExcerpt";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import "./Home.scss";

export const IndexPageTemplate = ({ image, title, description, telephone }) => (
  <div className="Home">
    <div className="Home__header">
      <h1>{title}</h1>
      <p className="Home__description">{description}</p>
      <a href={`tel:${telephone}`} className="Home__telephone Home__Button">
        {telephone}
      </a>
    </div>
    <div className="Home__Actions">
      <SosMainExcerpt />
      <OperationsProgrammeesExcerpt />
    </div>
    <h3>Actualités</h3>
    <BlogRoll homepage />
    <Link className="Home__Button Home__MoreNews" to="/actualites">
      Voir les actualités
    </Link>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  telephone: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { image, title, description, telephone } = frontmatter || {};

  return (
    <Layout>
      <div className="Layout__header">
        <PreviewCompatibleImage
          className="Home__picture"
          imageInfo={{
            image: image,
            alt: `Header banner ${title}`,
          }}
        />
      </div>
      <div className="Layout__body">
        <IndexPageTemplate
          image={image}
          title={title}
          description={description}
          telephone={telephone}
        />
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        telephone
      }
    }
  }
`;
